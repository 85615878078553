<template>
	<w-layout v-if="localService" column fill-height>
		<w-flex shrink>
			<v-toolbar color="primary" dark flat height="78px">
				<w-layout align-center justify-space-between row>
					<w-flex shrink>
						<w-btn color="white" flat icon="arrow_back" mini @click="goBackToProject()" />
					</w-flex>
					<w-flex grow>
						<w-layout column>
							<w-flex text-truncate title v-text="value.title" />
							<w-flex caption font-italic v-text="`${$tc('projects.project', 1)} : ${project.name}`" />
						</w-layout>
					</w-flex>
					<w-flex shrink>
						<w-btn-delete v-if="canDelete" mini @click="deleteService" />
					</w-flex>
				</w-layout>
			</v-toolbar>
		</w-flex>
		<w-flex scroll-y>
			<w-section :title="$t('projects.general_informations') + ' :'">
				<template v-if="canUpdate" v-slot:header>
					<v-scale-transition>
						<w-btn v-show="!isUpdating" flat icon="edit" mini @click="launchServiceEdition()" />
					</v-scale-transition>
				</template>
				<w-text-info class="my-1" :text="$t(`projects.frequencies.${value.frequency.key}_sentence`)" />
				<w-text-alert v-if="catalogService.is_archived" class="my-1" :text="$t('projects.this_catalog_service_is_archived')" />
				<w-flex v-else class="pointer" primary--text shrink my-2 @click="goToCatalogServiceDetails()">
					<w-layout row wrap>
						<w-flex shrink>
							<w-icon color="primary" small>zoom_in</w-icon>
						</w-flex>
						<w-flex v-t="'projects.see_details_for_this_service'" class="text-underline" />
					</w-layout>
				</w-flex>
				<w-flex shrink>
					<w-layout row>
						<w-flex xs6 mr-1>
							<w-currency-input v-model="localService.price" :readonly="!isUpdating" />
						</w-flex>
						<w-flex xs6 ml-1>
							<w-duration-input v-model="localService.time_allocation" :label="$t('projects.time_allocation')" :readonly="!isUpdating" />
						</w-flex>
					</w-layout>
				</w-flex>
				<w-flex shrink>
					<w-layout justify-end row :style="'min-height:48px'">
						<w-btn v-show="isUpdating" @click="updateService">{{ $t('actions.save') }}</w-btn>
						<w-btn v-show="isUpdating" flat @click="cancelEditing()">{{ $t('actions.cancel') }}</w-btn>
					</w-layout>
				</w-flex>
			</w-section>
			<w-section no-margin :title="$t('projects.tasks_list') + ' :'">
				<w-text-alert v-if="!project.can_create_tasks" class="mt-3 mx-3" :text="$t(`projects.cannot_create_tasks_of_${project.status.key}_project`)" />
				<w-text-info
					v-else-if="nextCreationDate"
					class="mt-3 mx-3"
					:text="$t('projects.the_next_tasks_will_be_created', { date: $d(new Date(nextCreationDate)) })"
				/>
				<w-text-info v-else class="mt-3 mx-3" :text="$t('projects.every_tasks_has_been_created')" />
				<TasksList :value="value" />
			</w-section>
		</w-flex>
	</w-layout>
</template>

<script>
import ProjectsManagerModuleGuard from '@/mixins/ModulesGuards/Offers/ProjectsManagerModuleGuard'

import Project from '@/classes/Offers/Project'
import Service from '@/classes/Offers/Service'

export default {
	name: 'ServiceDetails',
	components: {
		TasksList: () => ({
			component: import('@/components/Offers/CustomersManager/Details/TasksList')
		})
	},
	mixins: [ProjectsManagerModuleGuard],
	props: {
		project: {
			type: Project,
			required: true
		},
		value: {
			type: Service,
			required: true
		}
	},
	data: function () {
		return {
			isUpdating: false,
			nextCreationDate: null,
			nextCreationDateLoading: false,
			localService: null
		}
	},
	computed: {
		canDelete: function () {
			return this.value.can_delete
		},
		canUpdate: function () {
			return this.value.can_update
		},
		catalogService: function () {
			return this.value.catalog_service
		}
	},
	watch: {
		value: {
			handler: function (newVal) {
				if (newVal?.id) {
					this.localService = newVal.clone()
					this.findNextCreationDate()
				} else {
					this.localService = null
				}
			},
			immediate: true
		}
	},
	methods: {
		cancelEditing: function () {
			this.localService = this.value.clone()
			this.isUpdating = false
		},
		deleteService: function () {
			return this.service.deleteService(this.value)
		},
		doFindNextCreationDate: function (accountingFirmId, vendorId, serviceId, cancelToken) {
			const foundServiceInterval = this.service.findServiceInterval(
				{
					accounting_firm_id: accountingFirmId,
					company_id: vendorId,
					service_id: serviceId,
					service_interval_id: 'next'
				},
				{
					show_error: false
				},
				cancelToken
			)
			const callAnswer = {
				cancelToken: foundServiceInterval.cancelToken,
				promise: foundServiceInterval.promise.then(res => res.data.data)
			}
			return {
				cancelToken: callAnswer.cancelToken,
				promise: callAnswer.promise
					.then(serviceInterval => {
						return serviceInterval.task_creation_date
					})
					.catch(() => {
						return null
					})
			}
		},
		findNextCreationDate: function () {
			this.nextCreationDate = null
			if (!this.project.can_create_tasks) {
				return
			}
			this.nextCreationDateLoading = true
			const callAnswer = this.doFindNextCreationDate(this.accountingFirmId, this.vendorId, this.value.id, this.nextCreationDateCancelToken)
			this.nextCreationDateCancelToken = callAnswer.cancelToken
			return callAnswer.promise
				.then(nextCreationDate => {
					this.nextCreationDate = nextCreationDate
				})
				.finally(() => {
					this.nextCreationDateLoading = false
				})
		},
		goBackToProject: function () {
			this.service.selectProject(this.project.id)
		},
		launchServiceEdition: function () {
			this.isUpdating = true
		},
		goToCatalogServiceDetails: function () {
			const catalogServiceId = this.value.catalog_service_id
			const categoryId = this.value.category_id
			this.appService.goTo({
				name: 'offers-category-catalog-service',
				params: {
					catalogServiceId: catalogServiceId.toString(16),
					categoryId: categoryId.toString(16)
				}
			})
		},
		updateService: function () {
			return this.doUpdateService(this.localService).then(() => {
				this.isUpdating = false
			})
		},
		doUpdateService: function (service) {
			return this.service
				.updateService(this.accountingFirmId, this.vendorId, service.id, service.forApi())
				.then(res => res.data.data)
				.then(data => {
					const serviceUpdated = new Service(data)
					this.eventBus.emit(this.events.SERVICE_UPDATED, serviceUpdated)
				})
		}
	}
}
</script>
